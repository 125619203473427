.DbDatabaseOptionsColumn {
    text-align: center;
}

.DbDatabaseLabel {
    margin-bottom: 1rem;
    padding: 0 0 4px 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    border-bottom: 1px solid #d9d9d9;
    opacity: 0.85;
}

@hack: true; @import "/src/src/antd-default-theme.less";