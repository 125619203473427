.SpecIcon {
    justify-content: center;
    display: flex;
    align-items: center;
}
.InstanceCardCentered {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    margin-top: 2px;
    height: fit-content;
}

@hack: true; @import "/src/src/antd-default-theme.less";